<template>
  <div>

    <span v-if="type && newComp" class="welcome-txt flex">
        <span><span class="ellipsis1" style="color:#F9F9F9;max-width:200px">{{newComp.name}}</span></span>欢迎您
        <span v-if="newComp.cert_stat==0" @click="goApprove()" style="margin-left:20px;color: #fff;cursor: pointer;">未认证</span>
        <span @click="approveLink()" v-if="newComp.cert_stat==1" style="margin-left:20px;color: #fff;">认证中</span>
        <span @click="approveLink()" v-if="newComp.cert_stat==3" style="margin-left:20px;color: #fff;">认证失败</span>
        <!-- <router-link v-if="newComp.cert_stat==0" to="/comp_base" style="margin-left:20px;color: #fff;">未认证</router-link>
        <router-link v-if="newComp.cert_stat==1" to="/comp_base" style="margin-left:20px;color: #fff;">认证中</router-link>
        <router-link v-if="newComp.cert_stat==3" to="/comp_base" style="margin-left:20px;color: #fff;">认证失败</router-link> -->
    </span>
    <div v-if="!type && newComp" class="block cert-block">
      <div v-if="newComp.cert_stat == 0">
        <i class="el-icon-warning-outline"></i>
        <span>您的企业信息尚未认证，请点击 &nbsp;</span>
        <a @click="goApprove()">去认证</a>
      </div>
      <div v-if="newComp.cert_stat == 1">
        <i class="el-icon-warning-outline"></i>
        <span>您的企业信息正在认证中，请点击</span>
        <a @click="approveLink()">查看审核进度</a>
        <b>（注：银行账户认证，需要点此进入，并输入打款金额）</b>
      </div>
      <div v-if="newComp.cert_stat == 2">
        <i class="el-icon-circle-check"></i>
        <span>您的企业信息认证已通过</span>
        <a @click="goApprove(1)">重新认证</a>
      </div>
      <div v-if="newComp.cert_stat == 3">
        <i class="el-icon-warning-outline"></i>
        <span>您的企业信息认证不通过，请点击 &nbsp;</span>
        <a @click="approveLink()">重新认证</a>
        <!-- <span class="icon iconfont icon-bianzu1"
                style="color: #CC995A;"></span>审核未通过，【企业基本信息】【银行信息】【法人信息】（提示有问题的页面）信息有误
                <router-link to="/comp_cert" style="color:rgb(53, 14, 230);margin-left: 20px;">重新认证</router-link> -->
      </div>

    </div>

    <el-dialog width="500px" @close="close" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
          <div v-if="popTitle == '企业认证'">
              <Sign :popTitle="popTitle" @data="refreshItems" @hide="hide" @signState="signState"></Sign>
          </div>
          <div v-if="popTitle == '遇到问题'">
              <SignBack :popTitle="popTitle" @hide="popVisible=false"></SignBack>
          </div>
      </el-dialog>
  </div>
</template>
<script>
import { goCompanyVerify, getCompInfoById } from "@/service/company";
import Sign from '@/components/sign/sign.vue';
import SignBack from '@/components/sign/signBack.vue';

export default {
  props: ["comp","type"],
  components: {
    Sign,SignBack
  },
  data() {
    return {
      popVisible: false,
      popTitle: null,
      legal: null,
      newComp: null
    };
  },
  created() {
    this.newComp = this.comp;
    // console.log(222,this.comp);
  },
  methods: {
    refreshItems() {
      if (this.$store.state.loginUser.user.comp_creator==1) {
        getCompInfoById(this.$store.state.loginUser.comp.id)
        .then(rst => {
          if (rst) {
            this.newComp = rst;
            this.$store.state.loginUser.comp.cert_stat = rst.cert_stat;
          }
        }).catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
      }
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
    hide() {
        this.popVisible = false;
        this.$message.success('状态已更新');
    },
    goApprove(type) { // type 1已认证通过重新认证
      if (type) {
        this.$confirm("确定要重新认证？", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            closeOnClickModal: false,
            type: "warning",
        }).then(() => {
            this.submit();
        });
      } else {
        this.submit();
      }
    },
    submit() {
      console.log(this.newComp);
      this.legal = {
        comp_id: this.newComp.id
      };
      goCompanyVerify(this.legal)
        .then(rst => {
          if (rst.verify_url) {
            window.open(rst.verify_url);
            this.signState("企业认证");
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    close() {
      getCompInfoById(this.$store.state.loginUser.comp.id).then(rst => {
        if (rst) {
          this.newComp = rst;
        }
      }).catch(err => {
        console.log(err);
        this.$message.error(err.message);
      });
    },
    approveLink() {
      if (this.newComp.fdd_verify_url) {
        window.open(this.newComp.fdd_verify_url);
        this.signState("企业认证");
      }
    }
  }
};
</script>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.welcome-txt {
    font-weight: 500;
    color: #CCCCCC;
    font-size: 16px;
    /* margin-right: 80px;
    margin-left: 30px; */
    >span {
        cursor: pointer;
    }
}
.ellipsis1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.cert-block {
  height: 60px;
  background: rgba(242, 248, 255, 1);
  border: 1px solid rgba(185, 218, 255, 1);
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 20px;
  color: #292929;
  > div {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
  }
  i {
    margin-right: 5px;
    font-size: 22px;
    &.el-icon-circle-check {
      color: #2bbaae;
    }
  }
  a {
    color: #016fff;
    margin: 0 10px;
  }
  b {
    color: #808080;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
